/**************************************************************/
// Capa de Servicios para el modulo de Avances de los Proyectos

import axios from "axios";
import store from "@/store/store.js";

export default new class {
    
    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };
    
    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }
    
    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/advances");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getData() {
        try {
            let response = await axios.get(this.#baseUrl + "/view/advances");
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getViewList() {
        try {
            let response = await axios.get(this.#baseUrl + "/view-list/advances");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/advances/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
  
    async addRecord(formData) {
        // console.log('Adding Record.', formData);
        try {
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("project_id", formData.project.id);
            recordData.append("comment", formData.comment);
            recordData.append("description", formData.description);
            recordData.append("date_advance", formData.date_advance);
            recordData.append("advance_percentage", formData.advance_percentage);
            recordData.append("active", formData.active);
            let response = await axios.post(this.#baseUrl + "/advances", recordData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                let data = response.data;
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    // Pushes posts to the server when called.
    async updateRecord(formData) {
        try {
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("project_id", formData.project.id);
            recordData.append("comment", formData.comment);
            recordData.append("description", formData.description);
            recordData.append("date_advance", formData.date_advance);
            recordData.append("advance_percentage", formData.advance_percentage);
            recordData.append("active", formData.active);
            recordData.append('_method', 'put');
            // console.log('Record-Data: ', recordData);
            let response = await axios.post(`${this.#baseUrl}/advances/${formData.id}`, recordData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                let data = response.data;
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async deleteRecord(id) {
        try {
            let response = await axios.delete(`${this.#baseUrl}/advances/${id}`);
            console.log('Respuesta: ', response);
            if (response.data.success) {
                console.log('Respuesta.data: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async uploadImage(advance_id, picture_id, imageFile) {
        if (imageFile) {
            try {
                let formData = new FormData();
                formData.append("advance_id", advance_id);
                formData.append("picture_id", picture_id);
                formData.append("file", imageFile);
                let response = await axios.post(this.#baseUrl + '/upload-image/advances', formData);
                if (response.data.success) {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }
    
    async deleteImage(advance_id, picture_id) {
        try {
            if (picture_id) {
                let formData = new FormData();
                formData.append("advance_id", advance_id);
                formData.append("picture_id", picture_id);
                let response = await axios.post(this.#baseUrl + '/delete-image/advances', formData);
                if (response.data.success) {
                    return response.data;
                } else {
                    return null;
                }
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        return valueError;
    }
    
}