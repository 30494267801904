<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="advances"
                :items-per-page="10"
                :search="searchAdvance"
                :loading="loadingData"
                :expanded="expanded"
                single-expand
                show-expand
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                        showFirstLastPage: true,
                        disableItemsPerPage: false,
                        itemsPerPageAllText: 'Todas',
                        itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1 text-md-h5' : ''">
                            <span>Avances de lo Proyectos</span>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-advance"
                                label="Buscar..."
                                name="findadvance"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchAdvance"
                                class="white--text mt-0 pt-0 mr-2"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-responsive>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialog" max-width="700" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="blue darken-1"
                                       dark
                                       depressed
                                       elevation="0"
                                       class="mb-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text class="mb-0 pb-0">
                                        <v-container>
                                            <v-row dense class="mt-0 pt-0">
                                                <v-col cols="12">
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.project"
                                                        :items="projectsList"
                                                        :rules="[v => !!v || 'Al menos un proyecto es obligatorio']"
                                                        item-text="name"
                                                        item-value="id"
                                                        item-color="primary"
                                                        return-object
                                                        label="Proyecto asociado"
                                                        hint="Seleccione el proyecto asociado"
                                                        required>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-textarea
                                                        dense
                                                        v-model="editedItem.comment"
                                                        label="Comentarios"
                                                        :rules="textRules"
                                                        no-resize
                                                        rows="6"
                                                        counter
                                                        required>
                                                    </v-textarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        dense
                                                        v-model="editedItem.description"
                                                        label="Descripción"
                                                        :rules="rules"
                                                        hint="Breve descripción con un máximo de 50 carácteres"
                                                        required>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                :value="computedDateFormatted"
                                                                clearable
                                                                label="Fecha de anotación"
                                                                readonly
                                                                prepend-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click:clear="editedItem.date_advance = null">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="editedItem.date_advance"
                                                            no-title
                                                            color="orange"
                                                            locale="es-ES"
                                                            @change="menu = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field-integer
                                                        v-model.number ="editedItem.advance_percentage"
                                                        label="Porciento de Avance"
                                                        v-bind:properties="{
                                                            dense: true,
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                        }"
                                                        v-bind:options="{
                                                            inputMask: '#########',
                                                            outputMask: '#########',
                                                            empty: null,
                                                            applyAfter: false,
                                                        }"
                                                        v-bind:focus="focus"
                                                        v-on:focus="focus = false"
                                                    />
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-checkbox
                                                        dense
                                                        v-model="editedItem.active"
                                                        label="¿Activo?"
                                                        :rules="[v => !!v || 'Debes de marcar Activo para continuar']"
                                                        required
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="primary" text @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-subtitle-1">
                                    ¿Estás seguro que desea eliminar este Avance de Proyecto?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeletePicture" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar esta imagen de la Galería?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDeletePicture">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirmPicture">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogError"
                            persistent
                            max-width="500">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error de Validación</span>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-h6 pa-12">{{ messageError }}</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn text @click.stop="closeDialogError">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogGalleryPhotos"
                            persistent hide-overlay
                            width="90%">
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogGalleryPhotos = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Administrador de Imágenes</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogGalleryPhotos = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <!--v-model="model"-->
                                                <v-slide-group
                                                    center-active
                                                    class="pa-0"
                                                    next-icon="mdi-arrow-right-drop-circle-outline"
                                                    prev-icon="mdi-arrow-left-drop-circle-outline"
                                                    show-arrows>
                                                    <v-slide-item
                                                        v-for="itemImage in editedItem.pictures"
                                                        :key="itemImage.id"
                                                        v-slot="{ active, toggle }">
                                                        <v-card
                                                            :color="active ? undefined : 'white'"
                                                            class="ma-4 rounded-lg"
                                                            height="auto"
                                                            width="250"
                                                            elevation="5"
                                                            @click="toggle">
                                                            <v-img
                                                                :src="itemImage.url != null ? itemImage.url : require('@/assets/images/no-image.jpg')"
                                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                                                aspect-ratio="1"
                                                                height="250"
                                                                class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                                                <v-tooltip bottom v-if="itemImage.url != null">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            dark
                                                                            icon
                                                                            color="yellow"
                                                                            class="ma-2 text--darken-1"
                                                                            elevation="0"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            v-on:click="deletePicture(itemImage)">
                                                                            <v-icon>mdi-delete</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Eliminar imagen</span>
                                                                </v-tooltip>
                                                                <template v-slot:placeholder>
                                                                    <v-row
                                                                        class="fill-height ma-0"
                                                                        align="center"
                                                                        justify="center">
                                                                        <v-progress-circular
                                                                            indeterminate
                                                                            color="grey lighten-5"
                                                                        ></v-progress-circular>
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                        </v-card>
                                                    </v-slide-item>
                                                </v-slide-group>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-0 pt-0">
                                    <v-file-input v-model="selectedFiles"
                                                  small-chips
                                                  accept="image/png, image/jpeg, image/bmp"
                                                  show-size
                                                  multiple
                                                  clearable
                                                  label="Agregar ficheros"
                                                  @change="inputChanged">
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip small text-color="white" color="#295671" close
                                                    @click:close="removeChip(index)">
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <div v-if="imageFiles">
                                        <h5>Archivos seleccionados</h5>
                                        <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                                            {{ f.name }}
                                        </v-chip>
                                        <div class="py-3">
                                            <v-btn color="primary" @click="uploadPictures()" :disabled="selectedFiles.length === 0">
                                                Actualizar Galería
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox
                        v-model="item.active"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.date_advance="{ item }">
                    <span>{{ formatDate( item.date_advance) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadGalleryImages(item)">
                                mdi-camera
                            </v-icon>
                        </template>
                        <span>Administrar Imágenes</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h6">No hay Avances de Proyecto en el listado</span>
                    <br/>
                    <v-btn color="primary" @click="getAdvances">
                        Recargar
                    </v-btn>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="ma-1">
                                <h3>{{ item.project.name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="8">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha de anotación:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate( item.date_advance) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Comentario:
                                        </v-col>
                                        <v-col>
                                            {{ item.comment }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Descripción:
                                        </v-col>
                                        <v-col>
                                            {{ item.description }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Porciento de Avance:
                                        </v-col>
                                        <v-col>
                                            {{ item.advance_percentage }}%
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-row justify="center">
                                        <span class="font-weight-bold">
                                            Vista previa de imagen
                                        </span>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-img
                                            :src="item.pictures[0] != null ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            max-height="150"
                                            max-width="150"
                                            aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2">
                                        </v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import advanceService from '@/providers/AdvancesService';
    import projectsService from "@/providers/ProjectsService";
    import moment from 'moment'
    
    export default {
    name: "AdvancesModuleComponent",
        props: {
            title: String,
            breadcrumbs: Array,
            icon: String,
        },
        data: () => ({
            // MapBox Location
            placeNameLimit: 60,
            selectedPlace: {
                place_name: '',
                center: []
            },
            archived: false,
            placesList: [],
            loadingPlaces: false,
            searchQuery: null,
            menu: false,
            focus: false,
            snackBar: false,
            snackText: '',
            valid: true,
            searchAdvance: "",
            loadingData: false,
            dialog: false,
            dialogDelete: false,
            dialogError: false,
            dialogDeletePicture: false,
            dialogGalleryPhotos: false,
            currentPicture: undefined,
            selectedFiles: [],
            imageFiles: [],
            advances: [],
            projectsList: [],
            messageError: '',
            expanded: [],
            headers: [
                {text: '', value: 'data-table-expand', width: 5},
                {text: "Id", value: "id", align: " d-none"}, // para ocultar la columna Id.
                {
                    text: "Nombre del Proyecto",
                    align: "start",
                    sortable: false,
                    value: "project.name"
                },
                {text: "Fecha", value: "date_advance"},
                {text: 'Activo', value: 'active', align: 'center', width: 100},
                {text: "Acciones", value: "actions", width: 105, sortable: false}
            ],
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio'
            ],
            rules: [v => v.length <= 50 || 'Solo se permite un máximo 50 carácteres'],
            wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            imageRules: [
                value =>
                    !value ||
                    value.size < 2000000 ||
                    "El tamaño de la imagen no debe de sobrepasar los 2 MB"
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                project_id: 0,
                project: null,
                comment: '',
                description: '',
                date_advance: new Date(Date.now()).toISOString(),
                advance_percentage: 0,
                active: true,
                projects: null,
                pictures: []
            },
            defaultItem: {
                id: 0,
                project_id: 0,
                project: null,
                comment: '',
                description: '',
                date_advance: new Date(Date.now()).toISOString(),
                advance_percentage: 0,
                active: true,
                projects: null,
                pictures: []
            }
        }),
        filter: {
            formatTheDate(value) {
                if (value) {
                    return moment(String(value)).format('DD/MM/yyyy');
                }
            },
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "Nuevo Avance de Proyecto" : "Editar Avance de Proyecto";
            },
            computedDateFormatted () {
                return this.editedItem.date_advance ? this.formatDate(this.editedItem.date_advance) : '';
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            dialogDeletePicture(val) {
                val || this.closeDeletePicture();
            },
            searchQuery(val) {
                if (this.queryReady(val)) {
                    this.searchPlaces(val);
                }
            }
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0,10);
                const [year, month, day] = theDate.split('-')
                return `${day}/${month}/${year}`;
            },
            parseDate (date) {
                if (!date) return null;
                let theDate = date.toString().substr(0,10);
                const [month, day, year] = theDate.split('/');
                return `${day.padStart(2,'0')}/${month.padStart(2,'0')}/${year}`;
            },
            getAdvances() {
                this.loadingData = true;
                // Lista de Proyectos
                const archived = Number(this.archived);
                projectsService.getViewList(archived).then(record => {
                    this.projectsList = record.value;
                });
                // Lista de Avances de Proyectos
                advanceService.getViewList().then(record => {
                    this.advances = record.value;
                    this.loadingData = false;
                });
            },
            newItem() {
                this.valid = false;
                this.editedItem = Object.assign({}, this.defaultItem);
            },
            editItem(item) {
                this.editedIndex = this.advances.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.advances.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                advanceService.deleteRecord(this.editedItem.id).then(
                    response => {
                        if (response.success) {
                            this.advances.splice(this.editedIndex, 1);
                            this.snackText = 'Avance de Proyecto eliminado con éxito.';
                            this.getAdvances();
                        } else {
                            this.snackText = 'Un error impidió eliminar el Avance de Proyecto.';
                        }
                    }
                )
                this.closeDelete();
                this.snackBar = true;
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.advances[this.editedIndex], this.editedItem);
                        advanceService.updateRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getAdvances();
                                }
                            }
                        );
                    } else {
                        // Agrega el registro por el metodo POST
                        this.advances.push(this.editedItem);
                        advanceService.addRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getAdvances();
                                }
                            }
                        )
                    }
                    this.close();
                    this.snackText = 'Avance guardado con éxito.';
                    this.snackBar = true;
                }
            },
            /* Para administrar las imagenes */
            loadGalleryImages(item) {
                this.selectedFiles = [];
                this.imageFiles = [];
                // Carga las imagenes...
                advanceService.getRecord(item.id).then(fetch_data => {
                    this.editedItem = Object.assign({}, fetch_data.value);
                });
                if (!this.dialogGalleryPhotos) this.dialogGalleryPhotos = true;
            },
            removeChip(index) {
                this.imageFiles.splice(index, 1);
                this.selectedFiles = [
                    ...this.imageFiles,
                ];
            },
            inputChanged() {
                this.imageFiles = [
                    ...this.selectedFiles,
                ];
            },
            uploadPictures() {
                let errorCount = 0;
                for (let i = 0; i < this.imageFiles.length; i++) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                    };
                    reader.readAsDataURL(this.imageFiles[i]);
                    advanceService.uploadImage(this.editedItem.id, -1, this.imageFiles[i]).then(result => {
                        if (result.success) {
                            this.loadGalleryImages(this.editedItem);
                        } else {
                            errorCount++;
                        }
                    });
                }
                this.snackText = (errorCount > 0) ?
                    'Un error impidió subir al menos una imagen al servidor.' :
                    'Imagen subida al servidor. Galería actualizada.';
                this.snackBar = true;
            },
            deletePicture(item) {
                // Elimina la imagen seleccionada desde la bd y del servidor...
                this.currentPicture = Object.assign({}, item);
                this.dialogDeletePicture = true;
            },
            deleteItemConfirmPicture() {
                advanceService.deleteImage(this.currentPicture.pivot.advance_id, this.currentPicture.id).then(result => {
                    this.closeDeletePicture();
                    if (result.success) {
                        this.snackText = 'Imagen eliminada desde la galería.';
                        this.loadGalleryImages(this.editedItem);
                    } else {
                        this.snackText = 'Un error impidió eliminar la imagen desde la galería.';
                    }
                    this.snackBar = true;
                });
            },
            closeDeletePicture() {
                this.dialogDeletePicture = false;
            },
            changePreviewImage() {
                if (this.imageFile) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                    };
                    reader.readAsDataURL(this.imageFile);
                    if (this.editedItem.pictures.length === 0) {
                        let imagePicture = {
                            id: 2,
                            module_id: 2,
                            url: this.imageFile.name
                        };
                        this.editedItem.pictures.push(imagePicture);
                    }
                }
            },
            closeDialogError() {
                this.dialogError = false;
                this.messageError = '';
            }
        },
        mounted() {
            this.advances = [];
            this.getAdvances();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>